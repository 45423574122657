import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="hero-area">
      <div className="container">
        <div className="row">
          <div className="col-md-7 offset-md-5">
            <h1>Page not found!</h1>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
